<template>
  <div class="module-wrap" v-loading="loading" element-loading-text="加载中...">
    <el-button class="add-module" @click="dialogVisible = true">新增模板</el-button>
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column prop="ID" label="模板ID" width="180"></el-table-column>
      <el-table-column prop="Title" label="名称" width="180"></el-table-column>
      <el-table-column prop="CreatedAt" label="申请时间"></el-table-column>
      <el-table-column prop="Content" label="短信模板"></el-table-column>
      <el-table-column prop="Status" label="审核状态">
        <template #default="scope">
          {{ moduleObj[scope.row.Status] }}
        </template>
      </el-table-column>
      <!--      <el-table-column-->
      <!--          fixed="right"-->
      <!--          label="操作"-->
      <!--          width="100">-->
      <!--        <template #default="scope">-->
      <!--          <el-button @click="seeDialogVisible= true" type="text" size="small">查看</el-button>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
    </el-table>
    <el-pagination
      class="page"
      @current-change="onChangePage"
      v-model:current-page="page"
      :page-size="pageSize"
      layout="prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
    <el-dialog title="新增模板" v-model="dialogVisible" width="40%" :before-close="handleClose">
      <el-form>
        <el-row>
          <el-form-item label="主题" label-width="100px">
            <el-input v-model="title" autocomplete="off"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="主题内容" label-width="100px">
            <el-input type="textarea" v-model="content" maxlength="300" />
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="选择签名" label-width="100px">
            <el-select v-model="signId" placeholder="请选择签名">
              <el-option v-for="(item, index) in moduleList" :key="index" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="选择模版类型" label-width="100px">
            <el-select v-model="type" placeholder="请选择模版类型">
              <el-option label="通知" value="notify" />
              <el-option label="验证码" value="captcha" />
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="申请原因" label-width="100px">
            <el-input type="textarea" v-model="applyReason" maxlength="300" />
          </el-form-item>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog title="查看模板" v-model="seeDialogVisible" width="40%">
      <el-form>
        <el-row>
          <el-form-item label="主题" label-width="100px">
            <el-input v-model="name" autocomplete="off" readonly />
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="主题内容" label-width="100px">
            <el-input type="textarea" v-model="name" maxlength="300" readonly />
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="操作" label-width="100px">
            <el-radio-group v-model="checkStatus">
              <el-radio :label="0">通过</el-radio>
              <el-radio :label="1">拒绝</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-row>
        <el-row v-if="checkStatus === 1">
          <el-form-item label="不通过理由" label-width="100px">
            <el-input type="textarea" autosize placeholder="请填写不通过理由" />
          </el-form-item>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="seeDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="seeDialogVisible = false">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { MODULE_OBJ } from '@/config/constEnum'
import { defineComponent, onMounted, ref } from 'vue'
import axios from 'axios'
import _ from 'lodash'
import { ElMessage } from 'element-plus'
export default defineComponent({
  name: 'Module',
  setup() {
    const pageSize = 10
    const total = ref(0)
    const tableData = ref([])
    const moduleList = ref([])
    const page = ref(1)
    const title = ref('')
    const signId = ref('')
    const type = ref('captcha')
    const content = ref('')
    const applyReason = ref('')
    const dialogVisible = ref(false)
    const checkStatus = ref(0)
    const handleClose = () => {
      dialogVisible.value = !dialogVisible.value
    }
    const seeDialogVisible = ref(false)
    const loading = ref(false)

    const onChangePage = val => {
      page.value = val
      getList()
    }

    const getList = () => {
      loading.value = true
      const reqData = {
        status: 'all',
        size: pageSize,
        page: page.value
      }
      axios
        .get('/-/user/template/list', { params: reqData })
        .then(res => {
          const data = res.data.msg
          total.value = data.total
          tableData.value = data.template
        })
        .finally(() => {
          loading.value = false
        })
    }

    const clearReq = () => {
      title.value = ''
      signId.value = ''
      content.value = ''
      applyReason.value = ''
    }

    const submit = () => {
      const reqData = {
        title: title.value,
        type: type.value,
        sign_id: signId.value,
        content: content.value,
        apply_reason: applyReason.value
      }

      if (!title.value) return ElMessage.warning('请输入主题')
      if (!signId.value) return ElMessage.warning('请选择模板')
      if (!content.value) return ElMessage.warning('请填写主题内容')
      if (!applyReason.value) return ElMessage.warning('请填写申请原因')
      if (!type.value) return ElMessage.warning('请选择模版类型')

      axios.post('/-/user/template/new', reqData).then(() => {
        ElMessage.success('新增模板成功')
        clearReq()
        getList()
        dialogVisible.value = false
      })
    }

    //获取模版列表
    const getModuleList = () => {
      axios.get('/-/user/sign/all').then(res => {
        const data = res.data.msg
        moduleList.value = _.map(data.signs, item => {
          return {
            name: item.Name,
            id: item.ID
          }
        })
      })
    }
    onMounted(() => {
      getList()
      getModuleList()
    })
    return {
      page,
      type,
      total,
      title,
      signId,
      submit,
      loading,
      content,
      pageSize,
      tableData,
      moduleList,
      applyReason,
      handleClose,
      checkStatus,
      onChangePage,
      dialogVisible,
      seeDialogVisible,
      moduleObj: MODULE_OBJ
    }
  }
})
</script>
<style lang="less" scoped>
.module-wrap {
  position: relative;
  padding-top: 60px;
  background-color: #f2f2f2;

  .add-module {
    top: 10px;
    position: absolute;
    right: 20px;
  }

  .page {
    padding-top: 10px;
    text-align: right;
  }
}
</style>
